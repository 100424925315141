// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form__field {
  width: 100%;
  margin-bottom: 5rem;
}
.form__field.form__label {
  margin-bottom: 10rem;
  color: #404040;
  font-size: 14rem;
  line-height: 40rem;
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/FormField/FormField.style.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,mBAAA;AACJ;AACI;EACI,oBAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;AACR","sourcesContent":[".form__field {\n    width: 100%;\n    margin-bottom: 5rem;\n    \n    &.form__label {\n        margin-bottom: 10rem;\n        color: #404040;\n        font-size: 14rem;\n        line-height: 40rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
