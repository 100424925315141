// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-gap: 20rem;
}
@media (max-width: 660px) {
  .list {
    display: flex;
    flex-direction: column;
    height: auto;
  }
}
.list .item-wrap {
  position: relative;
  display: flex;
  padding: 32px 32px 64px 32px;
  flex-direction: column;
  align-items: flex-start;
  transform: translateX(-50rem);
  opacity: 0;
  transition: 0.6s opacity, 0.6s transform;
  border-radius: 32px;
  background: rgba(97, 160, 223, 0.25);
}
.list .item-wrap .plate-icon {
  position: absolute;
  right: 32px;
  bottom: 32px;
  width: 45rem;
}
.list .item-wrap-text {
  font-size: 15rem;
  line-height: 2;
  width: 75%;
}
.list .item-wrap:nth-child(odd) {
  transition-delay: 0.3s;
}
.list .item-wrap:nth-child(even) {
  transition-delay: 0.6s;
}
.main.loaded-done .item-wrap[visible] {
  opacity: 1;
  transform: translateX(0) translateY(0) rotate(0deg);
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/Plates/Plates.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,qCAAA;EACA,2BAAA;EACA,eAAA;AACJ;AACI;EAAA;IACI,aAAA;IACA,sBAAA;IACA,YAAA;EAEN;AACF;AAZA;EAaQ,kBAAA;EACA,aAAA;EACA,4BAAA;EACA,sBAAA;EACA,uBAAA;EACA,6BAAA;EACA,UAAA;EACA,wCAAA;EACA,mBAAA;EACA,oCAAA;AAER;AAxBA;EAyBY,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;AAEZ;AAAQ;EACI,gBAAA;EACA,cAAA;EACA,UAAA;AAEZ;AAAQ;EACI,sBAAA;AAEZ;AAAQ;EACI,sBAAA;AAEZ;AASA;EAEQ,UAAA;EACA,mDAAA;AARR","sourcesContent":[".list {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    grid-template-rows: 1fr 1fr;\n    grid-gap: 20rem;\n  \n    @media (max-width: 660px) {\n        display: flex;\n        flex-direction: column;\n        height: auto;\n    }\n\n    .item-wrap {\n        position: relative;\n        display: flex;\n        padding: 32px 32px 64px 32px;\n        flex-direction: column;\n        align-items: flex-start;\n        transform: translateX(-50rem);\n        opacity: 0;\n        transition: .6s opacity, .6s transform;\n        border-radius: 32px;\n        background: rgba(97, 160, 223, 0.25);\n\n        .plate-icon {\n            position: absolute;\n            right: 32px;\n            bottom: 32px;\n            width: 45rem;\n        }\n        &-text {\n            font-size: 15rem;\n            line-height: 2;\n            width: 75%;\n        }\n        &:nth-child(odd) {\n            transition-delay: 0.3s;\n        }\n        &:nth-child(even) {\n            transition-delay: 0.6s;\n        }\n        // &:nth-child(3) {\n        //     transition-delay: 0.6s;\n        // }\n        // &:nth-child(4) {\n        //     transition-delay: 0.9s;\n        // }\n    }\n}\n\n.main.loaded-done {\n    .item-wrap[visible] {\n        opacity: 1;\n        transform: translateX(0) translateY(0) rotate(0deg);\n    }\n}\n\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
