// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-descriptions .ant-descriptions-item-label {
  color: var(--primary-color);
  font-size: 16rem;
}
.ant-descriptions .ant-descriptions-item-content {
  color: white;
  font-size: 16rem;
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/Descriptions/Description.style.less"],"names":[],"mappings":"AAAA;EAEQ,2BAAA;EACA,gBAAA;AAAR;AAHA;EAOQ,YAAA;EACA,gBAAA;AADR","sourcesContent":[".ant-descriptions {\n    .ant-descriptions-item-label {\n        color: var(--primary-color);\n        font-size: 16rem;\n    }\n\n    .ant-descriptions-item-content {\n        color: white;\n        font-size: 16rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
