// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-select {
  width: 100%;
}
.ant-select > .ant-select-selector {
  border: 1px solid transparent !important;
  border-radius: 0%;
  background: #e2e2e2 !important;
  color: #404040;
  font-weight: 300;
  transition: 0.4s;
}
.ant-select.large > .ant-select-selector {
  height: 37rem;
  font-size: 14rem;
  line-height: 37rem;
}
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: var(--dark-primary-color) !important;
  box-shadow: none !important;
}
.ant-select-selection-item,
.ant-select-selection-placeholder {
  margin: auto;
}
.ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background: var(--light-gray);
}
.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background: var(--primary-color);
}
.ant-select-dropdown {
  border-radius: 0%;
  background: #eeecec;
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/Select/Select.style.less"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAEA;EACI,wCAAA;EACA,iBAAA;EACA,8BAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;AAAJ;AAGA;EACI,aAAA;EACA,gBAAA;EACA,kBAAA;AADJ;AAIA;;;;EAII,kDAAA;EACA,2BAAA;AAFJ;AAKA;;EACI,YAAA;AAFJ;AAKA;EACI,6BAAA;AAHJ;AAMA;EACI,gCAAA;AAJJ;AAOA;EACI,iBAAA;EACA,mBAAA;AALJ","sourcesContent":[".ant-select {\n    width: 100%;\n}\n\n.ant-select > .ant-select-selector {\n    border: 1px solid transparent !important;\n    border-radius: 0%;\n    background: #e2e2e2 !important;\n    color: #404040;\n    font-weight: 300;\n    transition: .4s;\n}\n\n.ant-select.large > .ant-select-selector {\n    height: 37rem;\n    font-size: 14rem;\n    line-height: 37rem;\n}\n\n.ant-select-focused .ant-select-selector,\n.ant-select-selector:focus,\n.ant-select-selector:active,\n.ant-select-open .ant-select-selector {\n    border-color: var(--dark-primary-color) !important;\n    box-shadow: none !important;\n}\n\n.ant-select-selection-item, .ant-select-selection-placeholder {\n    margin: auto;\n}\n\n.ant-select-item.ant-select-item-option.ant-select-item-option-active {\n    background: var(--light-gray);\n}\n\n.ant-select-item.ant-select-item-option.ant-select-item-option-selected {\n    background: var(--primary-color);\n}\n\n.ant-select-dropdown {\n    border-radius: 0%;\n    background: #eeecec;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
