// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset_pass_page {
  height: calc(100vh - 190rem);
}
.reset_pass_page .reset_pass__form_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ResetPassPage/ui/ResetPassPage/ResetPassPage.style.less"],"names":[],"mappings":"AAAA;EACI,4BAAA;AACJ;AAFA;EAGQ,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AAER","sourcesContent":[".reset_pass_page {\n    height: calc(100vh - 190rem);\n    .reset_pass__form_wrapper {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
