// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: var(--primary-color);
}
.ant-tabs .ant-tabs-tab-btn {
  font-size: 15rem;
}
.ant-tabs-left > .ant-tabs-content-holder {
  border-left: 1px solid #00AAB1;
  color: white;
}
.ant-tabs-tabpane.ant-tabs-tabpane-active {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  color: white;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary-color);
}
.ant-tabs-tabpane {
  display: flex;
}
.ant-tabs-tab-disabled {
  cursor: not-allowed;
}
.ant-tabs-tab-disabled span {
  color: gray;
}
@media (max-width: 660px) {
  .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 15rem;
  }
  .ant-tabs-content.ant-tabs-content-top {
    color: white;
  }
  .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 15px;
  }
  .ant-tabs-tabpane {
    flex-direction: column;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/Tabs/ORDTabs.style.less"],"names":[],"mappings":"AAAA;EACI,gCAAA;AACJ;AAEA;EACI,gBAAA;AAAJ;AAGA;EACI,8BAAA;EACA,YAAA;AADJ;AAIA;EACI,aAAA;EACA,eAAA;EACA,WAAA;AAFJ;AAKA;EACI,YAAA;AAHJ;AAMA;EACI,2BAAA;AAJJ;AAOA;EACI,aAAA;AALJ;AAQA;EACI,mBAAA;AANJ;AAKA;EAGQ,WAAA;AALR;AASA;EACI;IACI,gBAAA;EAPN;EAUE;IACI,YAAA;EARN;EAWE;IACI,kBAAA;EATN;EAYE;IACI,sBAAA;EAVN;AACF","sourcesContent":[".ant-tabs-ink-bar.ant-tabs-ink-bar-animated {\n    background: var(--primary-color);\n}\n  \n.ant-tabs .ant-tabs-tab-btn {\n    font-size: 15rem;\n}\n  \n.ant-tabs-left > .ant-tabs-content-holder {\n    border-left: 1px solid #00AAB1;\n    color: white;\n}\n  \n.ant-tabs-tabpane.ant-tabs-tabpane-active {\n    display: flex;\n    flex-wrap: wrap;\n    width: 100%;\n}\n  \n.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {\n    color: white;\n}\n  \n.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {\n    color: var(--primary-color);\n}\n  \n.ant-tabs-tabpane {\n    display: flex;\n}\n\n.ant-tabs-tab-disabled {\n    cursor: not-allowed;\n    span {\n        color: gray;\n    }\n}\n\n@media (max-width: 660px) {\n    .ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {\n        font-size: 15rem;\n    }\n  \n    .ant-tabs-content.ant-tabs-content-top {\n        color: white;\n    }\n  \n    .ant-tabs .ant-tabs-tab + .ant-tabs-tab {\n        margin: 0 0 0 15px;\n    }\n  \n    .ant-tabs-tabpane {\n        flex-direction: column;\n    }\n}\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
