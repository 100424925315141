// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-card-bordered {
  width: 30%;
  margin-right: 15px;
  margin-bottom: 20px;
  transition: 0.2s ease-in scale, 0.2s ease-in box-shadow;
}
.ant-card-bordered.hover:hover {
  box-shadow: 1px 1px 10px 1px rgba(115, 251, 253, 0.7);
  scale: 1.02;
}
div.ant-card-body .ant-card-meta > .ant-card-meta-detail > div.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  font-size: 11rem;
  text-overflow: ellipsis;
  white-space: normal;
  white-space: nowrap;
}
div.ant-card-body .ant-card-meta > .ant-card-meta-detail > .ant-card-meta-description {
  font-size: 9rem;
}
@media (max-width: 660px) {
  .ant-card-bordered {
    width: 100%;
    height: 50%;
    margin-right: 10px;
    margin-bottom: 20px;
  }
  div.ant-card-body .ant-card-meta > .ant-card-meta-detail > div.ant-card-meta-title {
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 16rem;
    white-space: normal;
  }
  div.ant-card-body .ant-card-meta > .ant-card-meta-detail > .ant-card-meta-description {
    font-size: 14rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/Card/ORDCard.style.less"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,kBAAA;EACA,mBAAA;EACA,uDAAA;AACJ;AACI;EACI,qDAAA;EACA,WAAA;AACR;AAGA;EACI,gBAAA;EACA,0BAAA;EACA,gBAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;AADJ;AAIA;EACI,eAAA;AAFJ;AAKA;EACI;IACI,WAAA;IACA,WAAA;IACA,kBAAA;IACA,mBAAA;EAHN;EAME;IACI,0BAAA;IACA,gBAAA;IACA,gBAAA;IACA,mBAAA;EAJN;EAOE;IACI,gBAAA;EALN;AACF","sourcesContent":[".ant-card-bordered {\n    width: 30%;\n    margin-right: 15px;\n    margin-bottom: 20px;\n    transition: .2s ease-in scale, .2s ease-in box-shadow;\n\n    &.hover:hover {\n        box-shadow: 1px 1px 10px 1px rgb(115 251 253 / .7);\n        scale: 1.02;\n    }\n}\n\ndiv.ant-card-body .ant-card-meta > .ant-card-meta-detail > div.ant-card-meta-title {\n    overflow: hidden;\n    color: rgb(0 0 0 / .88);\n    font-weight: 600;\n    font-size: 11rem;\n    text-overflow: ellipsis;\n    white-space: normal;\n    white-space: nowrap;\n}\n\ndiv.ant-card-body .ant-card-meta > .ant-card-meta-detail > .ant-card-meta-description {\n    font-size: 9rem;\n}\n\n@media (max-width: 660px) {\n    .ant-card-bordered {\n        width: 100%;\n        height: 50%;\n        margin-right: 10px;\n        margin-bottom: 20px;\n    }\n\n    div.ant-card-body .ant-card-meta > .ant-card-meta-detail > div.ant-card-meta-title {\n        color: rgb(0 0 0 / .88);\n        font-weight: 600;\n        font-size: 16rem;\n        white-space: normal;\n    }\n    \n    div.ant-card-body .ant-card-meta > .ant-card-meta-detail > .ant-card-meta-description {\n        font-size: 14rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
