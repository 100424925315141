// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider {
  display: flex;
}
.divider.vertical {
  height: 15rem;
  margin: 0 5px;
  border-left: 2px solid var(--gray);
}
.divider.horizontal {
  border-top: 2px solid var(--gray);
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/Divider/Divider.style.less"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EACI,aAAA;EACA,aAAA;EACA,kCAAA;AACR;AAEI;EACI,iCAAA;AAAR","sourcesContent":[".divider {\n    display: flex;\n\n    &.vertical {\n        height: 15rem;\n        margin: 0 5px;\n        border-left: 2px solid var(--gray);\n    }\n\n    &.horizontal {\n        border-top:  2px solid var(--gray);\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
