// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  display: flex;
  padding: 8rem 28rem;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  gap: 8rem;
}
.btn.primary {
  background: #02A7A6;
}
.btn.primary svg.btn-icon-suffix {
  fill: #fff;
}
.btn.primary[disabled] {
  background-color: #017272;
  opacity: 0.7;
  border: 1px solid #017272;
  color: #a4a3a3;
  cursor: not-allowed;
}
.btn.primary[disabled] svg.btn-icon-suffix {
  fill: #a4a3a3;
}
.btn.secondary {
  border: 1px solid #fff;
  fill: #fff;
}
.btn.link {
  color: #0EB0AF;
  width: max-content;
  padding: 0;
}
.btn.link svg.btn-icon-suffix {
  fill: #0EB0AF;
}
.btn.large {
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.btn.large svg.btn-icon-suffix {
  width: 17rem;
}
.btn.medium {
  padding: 5rem 25rem;
  font-size: 11rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.btn.medium svg.btn-icon-suffix {
  width: 14rem;
}
`, "",{"version":3,"sources":["webpack://./src/shared/ui/Button/Button.style.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,kBAAA;EACA,SAAA;AACJ;AACI;EACI,mBAAA;AACR;AAFI;EAGQ,UAAA;AAEZ;AAAQ;EACI,yBAAA;EACA,YAAA;EACA,yBAAA;EACA,cAAA;EACA,mBAAA;AAEZ;AAPQ;EAOQ,aAAA;AAGhB;AAEI;EACI,sBAAA;EACA,UAAA;AAAR;AAGI;EACI,cAAA;EACA,kBAAA;EACA,UAAA;AADR;AAFI;EAKQ,aAAA;AAAZ;AAII;EACI,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAFR;AAFI;EAOQ,YAAA;AAFZ;AAMI;EACI,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAJR;AADI;EAQQ,YAAA;AAJZ","sourcesContent":[".btn {\n    display: flex;\n    padding: 8rem 28rem;\n    border-radius: 12px;\n    align-items: center;\n    justify-content: center;\n    color: #fff;\n    text-align: center;\n    gap: 8rem;\n\n    &.primary {\n        background: #02A7A6;\n        svg.btn-icon-suffix {\n            fill: #fff;\n        }\n        &[disabled] {\n            background-color: #017272;\n            opacity: 0.7;\n            border: 1px solid #017272;\n            color: rgb(164, 163, 163);\n            cursor: not-allowed;\n            svg.btn-icon-suffix {\n                fill: rgb(164, 163, 163)\n            }\n        }\n    }\n\n    &.secondary {\n        border: 1px solid #fff;\n        fill: #fff;\n    }\n\n    &.link {\n        color: #0EB0AF;\n        width: max-content;\n        padding: 0;\n        svg.btn-icon-suffix {\n            fill: #0EB0AF;\n        }\n    }\n\n    &.large {\n        font-size: 14rem;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 160%;\n\n        svg.btn-icon-suffix {\n            width: 17rem;\n        }\n    }\n\n    &.medium {\n        padding: 5rem 25rem;\n        font-size: 11rem;\n        font-style: normal;\n        font-weight: 400;\n        line-height: 160%;\n\n        svg.btn-icon-suffix {\n            width: 14rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
