// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-result.handleError .ant-result-subtitle,
.ant-result.handleError .ant-result-title {
  color: white;
}
.error {
  height: calc(100vh - 202rem);
}
`, "",{"version":3,"sources":["webpack://./src/widgets/ErrorPage/ui/ErrorPage.style.less"],"names":[],"mappings":"AAAA;;EAEI,YAAA;AACJ;AAEA;EACI,4BAAA;AAAJ","sourcesContent":[".ant-result.handleError .ant-result-subtitle,\n.ant-result.handleError .ant-result-title {\n    color: white;\n}\n\n.error {\n    height: calc(100vh - 202rem);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
